<template>
  <div class="content-wrap" style="background: #fff">
    <div class="wrap">
      <header class="pt-32">
        <img class="logo_img mb-16" src="@/assets/image/medal_logo_green.png" />
        <div class="fm-12 f-g40 mb-16">파트너 가입 신청</div>
        <div class="navBar mb-32">
          <ul>
            <li class="ml-16 fm-14">1.개인 정보 입력</li>
            <li class="ml-16 fm-14">2.분야 입력</li>
            <li class="ml-16 fm-14 nav-selected">3.가입동의</li>
            <li class="ml-16 fm-14">4.가입신청 완료</li>
          </ul>
        </div>
      </header>
      <div class="contentBox">
        <div class="title_name mb-32">
          <span class="fm-16">약관동의</span>
          <p class="fr-12 mt-8">약관을 읽어보신 후 동의에 체크해주세요.</p>
        </div>
        <div class="agree-box-wrap p-24">
          <div class="agree-box" style="padding-bottom: 24px">
            <input-check v-model="allAgree" @input="checkAllAgree">
              <span class="fr-14 f-g70">아래 약관에 모두 동의합니다.</span>
            </input-check>
          </div>
          <div class="d-flex space-between align-items-center agree-box pl-8">
            <div class="mt-16 mb-8">
              <input-check v-model="serviceAgree" @input="checkAgree">
                <span class="fr-14 f-g70">서비스 이용약관</span>
              </input-check>
            </div>
            <a
              class="fr-14 f-g50 px-16"
              style="cursor: pointer"
              @click="openAgree('TERM')"
              >내용보기 ></a
            >
          </div>
          <div class="d-flex space-between align-items-center agree-box pl-8">
            <div class="mt-16 mb-8">
              <input-check v-model="privacyAgree" @input="checkAgree">
                <span class="fr-14 f-g70">개인정보 수집 및 이용 동의</span>
              </input-check>
            </div>
            <a
              class="fr-14 f-g50 px-16"
              style="cursor: pointer"
              @click="openAgree('PRIVACY')"
              >내용보기 ></a
            >
          </div>
          <div class="d-flex space-between align-items-center agree-box pl-8">
            <div class="mt-16 mb-8">
              <input-check v-model="tradeAgree" @input="checkAgree">
                <span class="fr-14 f-g70">전자금융거래 이용약관</span>
              </input-check>
            </div>
            <a
              class="fr-14 f-g50 px-16"
              style="cursor: pointer"
              @click="openAgree('ELECTRONIC')"
              >내용보기 ></a
            >
          </div>
          <div>
            <div class="d-flex space-between align-items-center pl-8">
              <div class="mt-16 mb-8">
                <input-check v-model="maketingAgree" @input="checkAgree">
                  <span class="fr-14 f-g70">제휴 마케팅 동의(선택)</span>
                </input-check>
              </div>
              <a
                class="fr-14 f-g50 px-16"
                style="cursor: pointer"
                @click="openAgree('MARKETING')"
                >내용보기 ></a
              >
            </div>
            <ul class="p-24 agree-li">
              <li class="fr-12 pb-8 f-g70">
                주요 공지사항 및 결제 관련 정보일 경우, 마케팅 동의 여부에
                관계없이 발송됩니다.
              </li>
            </ul>
          </div>
        </div>
        <div
          class="fr-14 f-red text-center p-24"
          v-if="!serviceAgree || !privacyAgree || !tradeAgree"
        >
          위 약관에 동의를 해주셔야 매달 서비스를 이용하실 수 있습니다.
        </div>
      </div>
    </div>
    <div class="next mb-80" style="margin-top: 62px">
      <button-common
        :size="SIZE_S"
        :clr="CLR_W"
        style="margin-right: 16px"
        @click="goBack()"
        >이전</button-common
      >
      <button-common
        :disabled="!serviceAgree || !privacyAgree || !tradeAgree"
        :size="SIZE_S"
        :clr="CLR_B"
        @click="regAgree()"
        >다음</button-common
      >
    </div>
  </div>
</template>
<script>
import { mapGetters } from "vuex";

export default {
  name: "Agree",
  components: {},
  data() {
    return {
      allAgree: false,
      serviceAgree: false,
      privacyAgree: false,
      tradeAgree: false,
      maketingAgree: false,
    };
  },
  /*** computed ***/
  computed: {
    ...mapGetters(["G_MODAL_TOGGLE", "G_AGREE_CODE"]),
  },
  /*** watch ***/
  watch: {
    async G_MODAL_TOGGLE(val) {
      // 열림
      if (!val) {
        this.setAgree();
      }
    },
  },
  methods: {
    async regAgree() {
      const rs = await this.$axios({
        url: "/partner/reg/agree",
        params: { maketingAgree: this.maketingAgree },
      });
      if (rs.result === "SUC") {
        this.goNextPage();
      }
    },
    checkAllAgree() {
      this.serviceAgree = this.allAgree;
      this.privacyAgree = this.allAgree;
      this.tradeAgree = this.allAgree;
      this.maketingAgree = this.allAgree;
    },
    checkAgree() {
      this.allAgree =
        this.serviceAgree &&
        this.privacyAgree &&
        this.tradeAgree &&
        this.maketingAgree;
    },
    setAgree() {
      switch (this["G_AGREE_CODE"]) {
        case "TERM":
          this.serviceAgree = true;
          this.checkAgree();
          break;
        case "PRIVACY":
          this.privacyAgree = true;
          this.checkAgree();
          break;
        case "ELECTRONIC":
          this.tradeAgree = true;
          this.checkAgree();
          break;
        case "MARKETING":
          this.maketingAgree = true;
          this.checkAgree();
          break;
      }
    },
    async openAgree(code) {
      this.$store.dispatch("A_AGREE_CODE", code);
      await this.$openAgree({}, this);
    },
    goNextPage() {
      this.$router.push({
        path: `/sign/finish`,
        meta: { id: "0-1-8" },
      });
    },
    clickUrl(url) {
      window.open(url);
    },
  },
};
</script>

<style scoped lang="scss">
@import "~@/assets/scss/join";
</style>
